/* BASE */
	html, body {
		background: $bg;
		font-family: $sor;
		margin: 0;
		padding: 0;
	}

/* BOUTONS */
	.btn-vert {
		background-color: $vert;
		color: #fff;
		font-family: $sshb;
		font-size: 14px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		display: block;
		margin-bottom: 15px;
	}
	#voir-les-resultats {
		margin-top: 12px;
	}
	#dupliquer {
		font-family: $sor;
		font-size: 8px;
		position: absolute;
		top: 8px;
		left: calc(60% - 5px);
		text-align: center;
	    background: transparent;
	    border: 0;
	    box-shadow: none;
		img {
			display: block;
			margin: 0 auto;
		}
	}
	.btn-blanc {
		display: block;
		background-color: #fff;
		color: $grisf;
		font-family: $sshb;
		font-size: 14px;
		height: 40px;
		line-height: 40px;
		text-align: center;
	}

/* HEADER */
	header {
		height: 40px;
		background-color: #fff;
		border-bottom: 1px solid $border;
	}
	#header-logo {
		line-height: 40px;
		font-family: $sshr;
		font-size: 10px;
		color: $grisf;
		margin-left: 10px;
		img {
			width: 100px;
		}
		span {
			&:before {
				content: "|";
				color: $border;
				margin-left: 3px;
				margin-right: 10px;
			}
		}
	}
	#header-symbol {
		line-height: 40px;
		float: right;
		margin-right: 10px;
		img {
			height: 25px;
		}
	}

/* SECTIONS */
	#saisie-des-donnees {
		position: relative;
		height: calc(100vh - 40px);
	}

/* TITRES DES COLONNES */
	.row-titre {
		text-align: center;
	}
	#nom-A, #nom-B {
		font-family: $sshb;
		font-size: 14px;
		color: $bleu !important;
		border: 0;
		border-bottom: 1px solid $border !important;
		background: transparent url('../img/pen.png') no-repeat right;
		text-align: center;
		display: block;
		width: 100%;
		border-radius: 0;
		padding-bottom: 7px;
		padding-top: 14px;
		margin-bottom: 16px;
	}

	#nom-A::-webkit-input-placeholder,
	#nom-B::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	 	color: $bleu;
		font-family: $sshb;
		font-size: 14px;
	}
	#nom-A::-moz-placeholder,
	#nom-B::-moz-placeholder { /* Firefox 19+ */
	 	color: $bleu;
		font-family: $sshb;
		font-size: 14px;
	}
	#nom-A:-ms-input-placeholder,
	#nom-B:-ms-input-placeholder { /* IE 10+ */
	 	color: $bleu;
		font-family: $sshb;
		font-size: 14px;
	}
	#nom-A:-moz-placeholder,
	#nom-B:-moz-placeholder { /* Firefox 18- */
	 	color: $bleu;
		font-family: $sshb;
		font-size: 14px;
	}

/* FILTRES (Modes de financement) */
	ul {
		display: block;
		overflow: hidden;
		margin: 0 auto 25px;
		padding: 0;
		text-align: center;

	}
	.radioButton {
		width: calc(33.33% - 8px);
		list-style-type: none;
		float: left;
		text-align: center;
		span {
			font-family: $sob;
			font-size: 11px;
			display: block;
			color: $grisf;
			background: #fff;
			border: 1px solid $border;
			height: 20px;
			line-height: 20px;
		}
		&:nth-child(2) {
			margin: 0 11px;
		}
	}
	.radioButton-A {
		span {
			background-color: $color1;
			border-color: $color1;
			color: #fff;
		}
	}
	.radioButton-B {
		span {
			background-color: $color2;
			border-color: $color2;
			color: #fff;
		}
	}

/* SLIDERS */
	.row-data {
		padding-top: 7px;
		padding-bottom: 8px;
	}
	.labelCol {
		font-family: $sob;
		font-size: 11px;
		color: $grisf;
		width: 26%;
		float: left;
		padding-left: 16px;
		span {
			font-size: 9px;
		}
	}
	.sliderCol {
		width: 37%;
		min-width: 37%;
		float: left;
		min-height: 1px;
		&:nth-child(odd) {
			padding-left: 30px;
			padding-right: 15px;
		}
		&:nth-child(even) {
			padding-left: 15px;
			padding-right: 30px;
		}
	}
	[class^="slider-"] .ui-state-default,
	[class^="slider-"] .ui-widget-content .ui-state-default,
	[class^="slider-"] .ui-widget-header .ui-state-default {
		position: absolute;
		top: -8px;
		background: $color1;
		border-radius: 100%;
		width: 22px !important;
		height: 22px !important;
		-webkit-box-shadow: 0px 2px 1px 0px rgba(0,0,0,0.16);
		-moz-box-shadow: 0px 2px 1px 0px rgba(0,0,0,0.16);
		box-shadow: 0px 2px 1px 0px rgba(0,0,0,0.16);
	}
	.ui-widget-content {
	    height: 2px !important;
	}
	[class^="slider-"] {
		width: 75%;
		float: left;
		position: relative;
		top: 8px;
		background-color: $border !important;
	}
	// Slider A
		.slider-A .ui-state-default,
		.slider-A .ui-widget-content .ui-state-default,
		.slider-A .ui-widget-header .ui-state-default {
			background: $color1;
		}
		.slider-A .ui-widget-header {
			background: $color1 !important;
			height: 2px !important;
		}
	// Slider B
		.slider-B .ui-state-default,
		.slider-B .ui-widget-content .ui-state-default,
		.slider-B .ui-widget-header .ui-state-default {
			background: $color2;
		}
		.slider-B .ui-widget-header {
			background: $color2 !important;
		    height: 2px !important;
		}
	.amountCell {
		width: 20%;
		float: right;
		input {
			background-color: $bg !important;
			font-family: $sor;
			font-size: 12px;
			color: #000;
			text-align: right;
			border: 0;
			width: 100%;
		}
	}

/* RÉSULTATS */
	h2 {
		font-family: $sshb;
		font-size: 14px;
		color: $bleu !important;
		border: 0;
		text-align: center;
		display: block;
		width: 100%;
		border-radius: 0;
		padding-bottom: 7px;
		padding-top: 14px;
		margin: 0 auto;
	}
	h3 {
		font-family: $sshr;
		font-size: 14px;
		color: $bleu !important;
		border: 0;
		text-align: center;
		display: block;
		width: 100%;
		border-radius: 0;
		padding-bottom: 7px;
		padding-top: 14px;
		margin: 0 auto;
	}
	#affichage-des-resultats {
		position: relative;
		display: none;
		float: left;
		width: 100%;
		padding-top: 10px;
	}

	#affichage-des-resultats .col-sm-4 {
		position: relative;
	}
	/* COUTS PAR AN */

	/* COUTS D'EXPLOITATION */
		#cout-consommation-A,
		#cout-mr-A,
		#cout-financement-A,
		#cout-exploitation-par-periode-A,
		#cout-exploitation-par-mois-A,
		#cout-exploitation-par-km-A,
		#cout-exploitation-par-an-A {
			background: $color1;
		}
		#cout-consommation-B,
		#cout-mr-B,
		#cout-financement-B,
		#cout-exploitation-par-periode-B,
		#cout-exploitation-par-mois-B,
		#cout-exploitation-par-km-B,
		#cout-exploitation-par-an-B{
			background: $color2;
		}

	/* COUTS PAR AN */
		.chart {
		    display: table;
		    border-collapse:separate;
		    border-spacing: 20px 5px;
		    table-layout: fixed;
		    text-align: center;
		    padding-left: 0;
		    margin: 0 auto;
				width: 96px;
		  li {
		    background: $border;
		    position: relative;
		    display: table-cell;
		    height: 100px;
		    width: 18px;
		  }
		  div {
		    display: block;
		    animation: draw 1s ease-in-out;
		    width: 18px;
		    position: absolute;
		    bottom: 0;
		  }
		}

	/* COUTS D'EXPLOITATION */
		#cout-exploitation-titre {
			margin-bottom: 15px;
		}
		.contentResultat{
			margin-bottom: 20px;
			text-align: center;
			position: relative;
		}
		.resultats-A{
			color: $color1;
		    font-family: $sob;
		    font-size: 18px;
		}
		.resultats-B{
			color: $color2;
		    font-family: $sob;
		    font-size: 18px;
		}
		.chiffre-valeur-resultat{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			min-width: 190px;
		}
		.valeur-A {
			display: block;
			font-family: $sor;
			font-size: 10px;
			text-transform: uppercase;
			line-height: 1;
			color: $color1;
	    font-family: $sob;
	    font-size: 11px;
			position: absolute;
			min-width: 50px;
			text-align: right;
		}
		.valeur-B {
			display: block;
			font-family: $sor;
			font-size: 10px;
			text-transform: uppercase;
			line-height: 1;
			color: $color2;
	    font-family: $sob;
	    font-size: 11px;
	    position: absolute;
			right: 0;
	    min-width: 50px;
			text-align: left;
		}
		.valeur-A-par-4 {
		    // left: 15px;
		}
		.valeur-B-par-4 {
		    // right: 15px;
		}
		.valeur-A-par-km {
		    // left: 30px;
		}
		.valeur-B-par-km {
		    // right: 30px;
		}
		.resultat {
			font-family: $sshb;
			font-size: 14px;
			text-align: center;
			margin-top: 5px;
			color: $grisf;
			span {
				display: block;
				font-family: $sshr;
				font-size: 10px;
			}
		}
		.resultat-A {
			color: $color1;
		}
		.resultat-B {
			color: $color2;
		}

	/* ECONOMIE PAR AN */
		#total-vehicule-A,
		#total-vehicule-B {
			padding: 45px 30px 30px;
			font-family: $sshb;
			color: #fff;
			display: block;
			font-size: 42px;
			line-height: 20px;
			position: relative;
			&:before {
			    position: absolute;
				top: -30px;
			    left: calc(50% - 31px);
			}
		}
		#total-vehicule-A {
			background-color: $color1;
			&:before {
				content: url("../img/label-A.svg");
			}
		}

		#total-vehicule-B {
			background-color: $color2;
			&:before {
				content: url("../img/label-B.svg");
			}
		}

		#total-identiques {
			padding:30px;
			font-family: $sshb;
			color: #fff;
			display: block;
			font-size: 18px;
			position: relative;
			background-color: $grisf;
		}

		#total-vehicule-A sup,
		#total-vehicule-B sup {
			font-size: 14px !important;
		    top: -1.25em !important;
    		left: 5px !important;
		}

		#total-vehicule-A span,
		#total-vehicule-B span {
			font-size: 14px !important;
			margin-top: 8px;
			display: inline-block;
		}

		#economie {
			text-transform: uppercase;
			font-family: $sor;
			font-size: 16px;
			margin: 0;
		}

		#resultat-par-an {
		    text-align: center;
		    margin-top: 20px;
		    margin-bottom: 20px;
		}

@keyframes draw { 0% { height: 0;  } }
